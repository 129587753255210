export const capitalize = (string: string) => {
  return string[0].toUpperCase() + string.slice(1);
};

export const formatNumberOfTiles = (value: number | undefined) => {
  return value === 0 ? '-' : value?.toLocaleString();
};

export const normalizeUsername = (username?: string) => {
  if (!username) {
    return '';
  }
  return username.trim().toLowerCase().replace(/\s/g, '-');
};

export const sanitizeUsername = (username: string) => {
  if (!username) {
    return '';
  }

  const sanitizedUsername = username.replace(/-/g, ' ');
  return sanitizedUsername.replace(/(^\w)|(\s+\w)/g, letter =>
    letter.toUpperCase(),
  );
};

export const usernameToThreeLetters = (username: string) => {
  if (!username) {
    return '';
  }
  return username
    .split('-')
    .map(part => part[0].toUpperCase())
    .join('-');
};

export const sanitizeWalletAddress = (walletAddress?: string) => {
  if (!walletAddress) {
    return '';
  }

  const sanitizedWalletAddress = `${walletAddress.slice(
    0,
    6,
  )}...${walletAddress.slice(-6)}`;
  return sanitizedWalletAddress;
};

export const capitalizeWords = (words: string[]) => {
  return words.map(word => {
    const firstLetter = word.charAt(0).toUpperCase();
    const restOfWord = word.slice(1);
    return firstLetter + restOfWord;
  });
};

export const initCapitalize = (input = '', delimiter = ' ') => {
  const words = input.split(delimiter);
  const capitalizedWords = capitalizeWords(words);
  return capitalizedWords.join(' ');
};

export const round = (value: number, decimals = 2) => {
  return Number(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals);
};

export const formattedLabel = (label?: string) => {
  if (!label) return '';
  let formattedDataLabel = label?.replaceAll('-', ' ') || 'unknown sign';

  if (formattedDataLabel === 'highway sign') {
    formattedDataLabel = 'highway exit sign';
  }

  formattedDataLabel = formattedDataLabel.replace(' eu', '');
  return formattedDataLabel;
};

export const upperCaseFirstLetter = (formattedDataLabel: string) => {
  const words = formattedDataLabel.split(' ');
  const upperCaseWords = words.map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  return upperCaseWords.join(' ');
};

export const getSublabel = (data: any): string => {
  const originalLabel = formattedLabel(data?.content?.label);
  let output = originalLabel;
  if (output !== 'white speed sign') {
    return output;
  }

  const consensusValues = data?.content?.consensusValuesByDetection;
  if (!consensusValues) return output;

  const uniqueLabels: { [key: string]: boolean } = {};
  Object.values(consensusValues).forEach((consensusValues: any) => {
    consensusValues.forEach((consensusValue: any) => {
      const label = consensusValue[0];
      if (label) uniqueLabels[label] = true;
    });
  });

  const labels = Object.keys(uniqueLabels);

  const numberLabels: string[] = labels.filter(label => !isNaN(Number(label)));
  if (numberLabels.length === 0) {
    output = 'null ' + originalLabel;
  } else {
    const numberLabelsString = numberLabels.join(', ');
    output = numberLabelsString + 'mph ' + originalLabel;
  }

  return output;
};

export const isJSON = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const parseCamelCase = (str: string) => {
  if (!str) return '';

  return str.replace(/([A-Z])/g, ' $1').toLowerCase();
};
